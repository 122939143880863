// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.findme__info__select, .information_form {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
}

.findme__info__select__label {
  font-size: 16px;
  font-weight: 500;
  padding: 16px 0 8px;
  text-align: left;
}

.findme__info__select__option {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px 0;
  justify-content: space-between;
}

.findme__info__select__option__element {
  min-width: calc(50% - 4px);
  max-width: calc(50% - 4px);
  flex: 1 1;
}

.findme__info__select__option__element input{
  display: none;
}

.findme__info__select__option__button {
  flex: 1 1;
  width: 100%;
  border: 1px solid rgba(32, 37, 40, 0.26);
  border-radius: 8px;
  height: 48px;
  background-color: transparent;
  color: rgba(32,37,40,0.5);
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 9px;
  transition: .25s ease-in-out;
}

.findme__info__select__option__button.big {
  height: 73px;
}

input:checked + .findme__info__select__option__button {
  background-color: rgba(0,155,146,1);
  border-color: rgba(0,155,146,1);
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/css/information.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,0BAA0B;EAC1B,0BAA0B;EAC1B,SAAO;AACT;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,SAAO;EACP,WAAW;EACX,wCAAwC;EACxC,kBAAkB;EAClB,YAAY;EACZ,6BAA6B;EAC7B,yBAAyB;EACzB,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,4BAA4B;AAC9B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mCAAmC;EACnC,+BAA+B;EAC/B,cAAc;AAChB","sourcesContent":[".findme__info__select, .information_form {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.findme__info__select__label {\n  font-size: 16px;\n  font-weight: 500;\n  padding: 16px 0 8px;\n  text-align: left;\n}\n\n.findme__info__select__option {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  padding: 8px 0;\n  justify-content: space-between;\n}\n\n.findme__info__select__option__element {\n  min-width: calc(50% - 4px);\n  max-width: calc(50% - 4px);\n  flex: 1;\n}\n\n.findme__info__select__option__element input{\n  display: none;\n}\n\n.findme__info__select__option__button {\n  flex: 1;\n  width: 100%;\n  border: 1px solid rgba(32, 37, 40, 0.26);\n  border-radius: 8px;\n  height: 48px;\n  background-color: transparent;\n  color: rgba(32,37,40,0.5);\n  font-weight: 500;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n  text-align: center;\n  margin-bottom: 9px;\n  transition: .25s ease-in-out;\n}\n\n.findme__info__select__option__button.big {\n  height: 73px;\n}\n\ninput:checked + .findme__info__select__option__button {\n  background-color: rgba(0,155,146,1);\n  border-color: rgba(0,155,146,1);\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

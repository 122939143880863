// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.findme__question__explanation {
  text-align: center;
  font-size: 13px;
  line-height: 1.6;
  color: rgba(126, 126, 126, 1);
  margin: 0 0 12px;
}

.findme__question__wrapper{
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 12px;
}

.findme__question__element__label{
  font-size: 16px;
  line-height: 1.6;
  font-weight: 500;
}

.findme__question__element__option {
  text-align: center;
  font-size: 12px;
  color: rgba(32, 37, 40, 0.4);
}

.findme__question__element__options {
  margin: 15px 0 24px;
  display: flex;
  justify-content: space-between;
}

.findme__question__element__option-Checker {
  width: 25px;
  height: 25px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid rgba(188,190,190,1);
}

input {
  display: none;
}

input:checked + .findme__question__element__option-Checker {
  width: 27px;
  height: 27px;
  margin: 0;
  border: 5px solid #009B92;
}
`, "",{"version":3,"sources":["webpack://./src/css/question.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,6BAA6B;EAC7B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,sBAAsB;EACtB,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,qCAAqC;AACvC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,yBAAyB;AAC3B","sourcesContent":[".findme__question__explanation {\n  text-align: center;\n  font-size: 13px;\n  line-height: 1.6;\n  color: rgba(126, 126, 126, 1);\n  margin: 0 0 12px;\n}\n\n.findme__question__wrapper{\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: flex-start;\n  margin-top: 12px;\n}\n\n.findme__question__element__label{\n  font-size: 16px;\n  line-height: 1.6;\n  font-weight: 500;\n}\n\n.findme__question__element__option {\n  text-align: center;\n  font-size: 12px;\n  color: rgba(32, 37, 40, 0.4);\n}\n\n.findme__question__element__options {\n  margin: 15px 0 24px;\n  display: flex;\n  justify-content: space-between;\n}\n\n.findme__question__element__option-Checker {\n  width: 25px;\n  height: 25px;\n  margin: 1px;\n  border-radius: 50%;\n  border: 2px solid rgba(188,190,190,1);\n}\n\ninput {\n  display: none;\n}\n\ninput:checked + .findme__question__element__option-Checker {\n  width: 27px;\n  height: 27px;\n  margin: 0;\n  border: 5px solid #009B92;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

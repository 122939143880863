// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.findme__loading {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.findme__loading__text {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: rgba(32, 27, 40, 1);
}

.findme__loading__animation {
  margin-top: 28px;
  display: flex;
  padding-right: 2px;
}

.findme__loading__animation--objet {
  height: 8px;
  width: 8px;
  background-color: rgba(32, 27, 40, 1);
  border-radius: 4px;
  margin: 5px;
  animation: 1.2s ease-in-out bounceBall infinite;
}

.objet--2 {
  animation-delay: .2s;
}
.objet--3 {
  animation-delay: .4s;
}

@keyframes bounceBall {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-9px);
  }
  60% {
    transform: translateY(-6px);
  }
}


.findme__loading__explanation{
  margin-top: 24px;
  text-align: center;
  font-size: 12px;
  color: rgba(32, 27, 40, 1);
}

`, "",{"version":3,"sources":["webpack://./src/css/found_me.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,uBAAuB;EACvB,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,qCAAqC;EACrC,kBAAkB;EAClB,WAAW;EACX,+CAA+C;AACjD;;AAEA;EACE,oBAAoB;AACtB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,2BAA2B;EAC7B;AACF;;;AAGA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,0BAA0B;AAC5B","sourcesContent":[".findme__loading {\n  height: 100vh;\n  width: 100vw;\n  position: fixed;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  align-content: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n.findme__loading__text {\n  font-size: 24px;\n  font-weight: 700;\n  text-align: center;\n  color: rgba(32, 27, 40, 1);\n}\n\n.findme__loading__animation {\n  margin-top: 28px;\n  display: flex;\n  padding-right: 2px;\n}\n\n.findme__loading__animation--objet {\n  height: 8px;\n  width: 8px;\n  background-color: rgba(32, 27, 40, 1);\n  border-radius: 4px;\n  margin: 5px;\n  animation: 1.2s ease-in-out bounceBall infinite;\n}\n\n.objet--2 {\n  animation-delay: .2s;\n}\n.objet--3 {\n  animation-delay: .4s;\n}\n\n@keyframes bounceBall {\n  0%, 20%, 50%, 80%, 100% {\n    transform: translateY(0);\n  }\n  40% {\n    transform: translateY(-9px);\n  }\n  60% {\n    transform: translateY(-6px);\n  }\n}\n\n\n.findme__loading__explanation{\n  margin-top: 24px;\n  text-align: center;\n  font-size: 12px;\n  color: rgba(32, 27, 40, 1);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
